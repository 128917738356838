@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@layer base {
	html {
		font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont,
			"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
			"Helvetica Neue", sans-serif;
	}
	* {
		box-sizing: border-box;
		padding: 0;
		margin: 0;
		scroll-behavior: smooth;
		font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont,
			"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
			"Helvetica Neue", sans-serif;
	}
}

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
	@tailwind base;
}

@layer tailwind-utilities {
	@tailwind components;
	@tailwind utilities;
}

.theme-font {
	font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont,
		"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
		"Helvetica Neue", sans-serif;
}

.sidebar-two-scroll::-webkit-scrollbar,
.children-scroll-bar::-webkit-scrollbar {
	width: 11px;
}

.sidebar-two-scroll,
.children-scroll-bar {
	scrollbar-width: thin;
	scrollbar-color: #e2e8f0 #f7fafc;
}

.children-scroll-bar::-webkit-scrollbar-track {
	background: #f7fafc;
}
.children-scroll-bar::-webkit-scrollbar-thumb {
	background-color: #e2e8f0;
	border-radius: 6px;
	border: 3px solid #f7fafc;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%234F4F4F'><polygon points='0,0 100,0 50,50'/></svg>")
		no-repeat;
	background-size: 12px;
	background-position: right 20px center;
	padding-right: 32px; /* Adjust the padding to make space for the arrow */
}

input:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
input.checkbox:disabled {
  opacity: 0.8;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.money-input {
  background-color: transparent;
  color: transparent;
  /* -webkit-text-fill-color: transparent; */
}

.even-row {
	background-color: #ffffff;
}

.odd-row {
	background-color: #f2f2f4;
}

.custom-pagination-button {
	background-color: #f58b00;
	/* Add any other desired styles */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tab_scrollbar_hidden::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.tab_scrollbar_hidden {
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
}

.radio-button {
	display: none;
}

.radio-label {
	display: flex;
	align-items: center;
}

.radio-custom {
	display: inline-block;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	border: 2px solid #000;
	margin-right: 0.5em;
}

.radio-button:checked + .radio-label .radio-custom {
	background-color: #000;
}

.react-calendar {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	color: #222;
	border: 1px solid #a0a096;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
}

/* Highlighting for the selected range */
.react-calendar__tile--range {
	background-color: #f0f0f0;
}

.table-action-popover {
	@apply flex items-center gap-3 py-3 pl-3 pr-5 hover:bg-slate-100 hover:cursor-pointer hover:rounded-lg
}

/* Custom classes for different leave types */
.annualLeave {
	background-color: #007bff !important; /* Blue */
	color: white;
}

.parentalLeave {
	background-color: #28a745 !important; /* Green */
	color: white;
}

.compassionateLeave {
	background-color: #dc3545 !important; /* Red */
	color: white;
}

/* This class is for the selected range */
.highlight {
	background: lightblue;
	color: black;
}

.legend-dot {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}

.annualLeave {
	background-color: #007bff; /* Blue */
}

.parentalLeave {
	background-color: #28a745; /* Green */
}

.compassionateLeave {
	background-color: #dc3545; /* Red */
}

/* Search Input Styles */
.relative {
	position: relative;
}

select {
	width: 100%;
	background-color: #f2f2f4;
	border-radius: 8px;
	height: 56px;
	padding: 0px 20px;
}

.color-code-tooltip::before {
	content: "";
	position: absolute;
	top: 50%;
	left: -20px;
	transform: translateY(-50%);
	border-width: 10px;
	opacity: 0.2;
	border-style: solid;
	border-color: transparent #27AE60 transparent transparent;
}

button.node-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.claim-status {
	--tw-bg-opacity: 0.2;
}

select.dark {
	@apply w-[120px] h-14 px-5 mt-2 text-sm rounded-l-none rounded-r-lg 
	text-white bg-black focus:outline-none
}

.transform-ease {
	transition: all 0.2s ease;
}

.table-filter select.filter {
	height: 48px;
}

.table-filter .sort-active {
	background-color: #4F4F4F;
	color: white;
}

.failed-permission-check * {
	cursor: not-allowed;
}

@media screen and (max-width: 1024px) {
	.mobile-full-width {
		width: calc(100% + 32px);
		transform: translateX(-16px);
	}
}